<template>
  <div class="p-4 md:p-16">
    <n-card embedded class="p-4 md:p-8">
      <h1>{{ t('notFound.title') }}</h1>
      <p>{{ t('notFound.text') }}</p>
    </n-card>
  </div>
</template>

<script setup lang="ts">
import { NCard } from 'naive-ui'
import { useI18n } from 'vue-i18n'
import { useMeta } from '@/composables/Meta'
import { computed } from 'vue'

const { t } = useI18n()

const { updateMetaData } = useMeta()

updateMetaData({
  noIndex: computed(() => true),
})
</script>
